import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import classnames from 'classnames';
import { useIntl, FormattedMessage } from 'react-intl';
import arrowImg from '../../images/home-page/icon_other/arrow.svg';
import fbImg from '../../images/social/social-fb.svg';
import twImg from '../../images/social/social-twitter.svg';
import instImg from '../../images/social/social-instagram.svg';
import { colors, Container } from '../../ui/common';
import LocalizedLink from '../localized-link';
import useStyles, { InputStatus, inputTheme } from './styled';
import Input from '../Checkout/ui/Input';
import Spinner from '../../ui/spinner';
import ContactUs from './ContactUs';
import Logo from '../Logo';

/* eslint-disable jsx-a11y/anchor-is-valid */
function Footer({ background }) {
  const intl = useIntl();
  const classes = useStyles({ background });
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ anim: false, text: '' });

  const onSubmitEmail = async (v) => {
    /* show anim */
    setStatus({ anim: true, text: '' });

    // request to the server.
    try {
      const res = await axios.post(`/api/subscribeUser`, { email: v });

      if (res.status === 200)
        setStatus({ anim: false, text: 'footer.subscribing.ok', error: false });
    } catch (e) {
      setStatus({
        anim: false,
        text: 'footer.subscribing.failed',
        error: true,
      });
    }
  };

  return (
    <footer className={classes.root}>
      <Container>
        <Grid container>
          <Grid item md={3} sm={4} xs={12} className={classes.block}>
            <Typography paragraph variant="h6" className={classes.title}>
              {intl.formatMessage({ id: 'footer.learn' })}
            </Typography>
            <div>
              <LocalizedLink to="shop" className={classes.link}>
                {intl.formatMessage({ id: 'footer.products' })}
              </LocalizedLink>
            </div>
            <div>
              <LocalizedLink to="technology" className={classes.link}>
                {intl.formatMessage({ id: 'footer.technology' })}
              </LocalizedLink>
            </div>
            <div>
              <Link href="/blog" className={classes.link}>
                {intl.formatMessage({ id: 'footer.blog' })}
              </Link>
            </div>
            <div>
              <LocalizedLink to="aff-program" className={classes.link}>
                {intl.formatMessage({ id: 'footer.aff-program' })}
              </LocalizedLink>
            </div>
            <div>
              <LocalizedLink to="cool-privilege" className={classes.link}>
                {intl.formatMessage({ id: 'footer.cool-privilege' })}
              </LocalizedLink>
            </div>
          </Grid>
          <Grid item md={3} sm={4} xs={12} className={classes.block}>
            <Typography paragraph variant="h6" className={classes.title}>
              {intl.formatMessage({ id: 'footer.support' })}
            </Typography>
            <div>
              <Link
                href="https://support.evapolar.com"
                className={classes.link}
              >
                {intl.formatMessage({ id: 'footer.help' })}
              </Link>
            </div>
            <div>
              <Link
                href="https://support.evapolar.com/hc/en-us/articles/115000899625-Return-Refund-policy"
                className={classes.link}
              >
                {intl.formatMessage({ id: 'footer.returns' })}
              </Link>
            </div>
            <div>
              <Link
                href="https://support.evapolar.com/hc/en-us/requests/new?ticket_form_id=360001043920"
                className={classes.link}
              >
                {intl.formatMessage({ id: 'footer.technical_help' })}
              </Link>
            </div>
            <div>
              <Link
                href="https://support.evapolar.com/hc/en-us/articles/360020470020-Delivery-general-information"
                className={classes.link}
              >
                {intl.formatMessage({ id: 'footer.delivery' })}
              </Link>
            </div>
            <div>
              {intl.locale === 'en' ? (
                <LocalizedLink to="accessibility" className={classes.link}>
                  {intl.formatMessage({ id: 'footer.accessibility' })}
                </LocalizedLink>
              ) : (
                <div />
              )}
            </div>
          </Grid>
          <Grid item md={3} sm={4} xs={12} className={classes.block}>
            <Typography paragraph variant="h6" className={classes.title}>
              {intl.formatMessage({ id: 'footer.contact-us' })}
            </Typography>
            <div>
              {intl.locale === 'ru' ? (
                <LocalizedLink to="service-ru" className={classes.link}>
                  {intl.formatMessage({ id: 'footer.customer_help' })}
                </LocalizedLink>
              ) : (
                <Link
                  href="https://support.evapolar.com/hc/en-us/requests/new"
                  className={classes.link}
                >
                  {intl.formatMessage({ id: 'footer.customer_help' })}
                </Link>
              )}
            </div>
            <pre style={{ marginTop: 0 }} className={classes.link}>
              <ContactUs />
            </pre>
          </Grid>
          <Grid
            item
            md={3}
            sm={12}
            xs={12}
            className={classnames(classes.block, classes.readyBlock)}
          >
            <Typography paragraph variant="h6" className={classes.title}>
              <FormattedMessage id="footer.ready" />
            </Typography>
            <Typography paragraph variant="h6" className={classes.textSignUp}>
              {intl.formatMessage({ id: 'footer.sign_up' })}
            </Typography>
            <div>
              <Input
                name="email"
                label="footer.email"
                value={email}
                onChange={(_, v) => setEmail(v)}
                autocomplete="email"
                icon={arrowImg}
                theme={inputTheme}
                onIconClick={onSubmitEmail}
              />
              <InputStatus error={status.error}>
                {status.anim && <Spinner />}
                {status.text !== '' && <FormattedMessage id={status.text} />}
              </InputStatus>
            </div>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item sm={6} xs={12}>
            <Logo textColor={colors.color15} />
            <Typography paragraph variant="h6" className={classes.copyright}>
              {`© Evapolar, 2016–${new Date().getFullYear()}`}
            </Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Grid container>
              <Grid container className={classes.containerSocial}>
                <Link
                  href="https://www.facebook.com/evapolarofficial/"
                  className={classes.link}
                >
                  <img src={fbImg} className={classes.social} alt="Facebook" />
                </Link>
                <Link
                  href="https://www.instagram.com/evapolar"
                  className={classes.link}
                >
                  <img
                    src={instImg}
                    className={classes.social}
                    alt="Instagram"
                  />
                </Link>
                <Link
                  href="https://twitter.com/evapolar_com"
                  className={classes.link}
                >
                  <img src={twImg} className={classes.social} alt="Twitter" />
                </Link>
              </Grid>
              <Grid container className={classes.containerItems}>
                <LocalizedLink
                  to="terms"
                  className={classnames(classes.text, classes.textBottom)}
                >
                  {intl.formatMessage({ id: 'footer.terms' })}
                </LocalizedLink>
                <LocalizedLink
                  to="privacy-policy"
                  className={classnames(classes.text, classes.textBottom)}
                >
                  {intl.formatMessage({ id: 'footer.privacy' })}
                </LocalizedLink>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
}
/* eslint-enable jsx-a11y/anchor-is-valid */

Footer.propTypes = {
  background: PropTypes.string,
};
Footer.defaultProps = {
  background: colors.color06,
};

export default Footer;
