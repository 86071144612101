import Link from '@material-ui/core/Link';
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import officesCountryCodes from '../../domain/offices-country-codes';
import useStyles from './styled';

function ContactUs() {
  const intl = useIntl();
  const classes = useStyles();
  const country = useSelector((state) => state.countries.picked);
  const countryInLowerCase = country.toLowerCase();
  const isOfficeInCountry = officesCountryCodes.some(
    (code) => code.toLowerCase() === countryInLowerCase
  );
  const idPostfix = isOfficeInCountry ? countryInLowerCase : 'default';
  const email = intl.formatMessage({ id: 'footer.email.address' });

  return (
    <>
      <Link href={`mailto:${email}`} className={classes.link}>
        {email}
      </Link>
      <FormattedMessage id={`footer.office_location.${idPostfix}`} />
    </>
  );
}

export default ContactUs;
